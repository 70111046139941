(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-grid/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-grid/assets/javascripts/init.js');
"use strict";


const init = options => {
  const $widgetEl = options.$el[0];
  if (svs.core.detect.formfactor.mobile()) {
    svs.components.lbUi.productsCarousel.init($widgetEl);
  } else {
    svs.components.lbUi.productsGridFlexible.init($widgetEl);
  }
};
setGlobal('svs.banner_groups.banner_grid', {
  init
});

 })(window);