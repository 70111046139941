(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-grid/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-grid/assets/javascripts/render-component.js');
"use strict";



const isServer = typeof exports === 'object';

const getLogger = isServer ? require('trinidad-core').core.logger : svs.core.log.getLogger;
const logger = getLogger('banner-groups: banner-grid');
const performanceLogger = getLogger('banner-grid:performance');

async function renderComponent(widgetData, widgetInfo, req, res) {
  const {
    widgetId,
    title,
    subTitle,
    layout,
    minCountDummy,
    isMobile: _isMobile
  } = widgetData.config;
  const _renderSingleBannerItem = async (bannerItem, index) => {
    const t0 = performance.now();
    const _perf = returnIndex => performanceLogger.info("renderSingleBannerItem() took ".concat(Math.round(performance.now() - t0), " milliseconds (index ").concat(index, ", return ").concat(returnIndex, "/3)"));
    const {
      id,
      layoutType
    } = bannerItem;
    const pos = index + 1;
    try {
      if (!layoutType) {
        logger.warn("Banner with ID ".concat(id, " lacks layoutType property"));
        _perf(1);
        return '';
      }
      const {
        renderComponent: renderAsync
      } = isServer ? widgetData.components["banner_layouts/".concat(layoutType)] :
      svs.banner_layouts[layoutType.replace(/-/g, '_')];
      const bannerHtml = await renderAsync(bannerItem, widgetInfo, pos, req, res);
      _perf(2);
      return bannerHtml;
    } catch (error) {
      logger.warn("Grid banner with ID ".concat(id, " skipped due to banner loading error: ").concat(error.toString()), req);
      _perf(3);
      return '';
    }
  };
  const renderedBanners = await Promise.all(widgetData.banners.map(_renderSingleBannerItem));
  const childElements = renderedBanners.filter(Boolean);
  const model = {
    widgetId,
    title,
    subTitle,
    layout,
    childElements,
    _isMobile,
    _hasEnoughChildren: childElements.length >= (minCountDummy || 3)
  };
  return getCompiledTemplate('grid')(model) || '';
}

function getCompiledTemplate(name) {
  if (isServer) {
    return global.internalInstances.get('hbs').cache["components-banner_groups-banner_grid-".concat(name)];
  }
  return svs.banner_groups.banner_grid.templates[name];
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_groups.banner_grid', {
    renderComponent
  });
}

 })(window);